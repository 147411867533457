.v-window-item {
  transition: none !important;
}

.v-tab {
  border: solid 1px #e8e8e8;
}

.v-btn {
  text-transform: capitalize;
}

.v-data-table {
  height: 290px;
  overflow-y: scroll;
}
.search-input {
  .v-input__slot {
    padding-left: 0.5em;
    margin-bottom: 0 !important;
  }

  .v-text-field__details {
    //display: none;
  }
}

// Fonts
@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,600");
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,600,900");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,300i");
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800");
// Variables
@import 'variables';
//
//html {
//  zoom: 80%;
//}
// Bootstrap
//@import '~bootstrap/scss/bootstrap';
// Core
@import "~bootstrap/dist/css/bootstrap.css";
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~font-awesome/css/font-awesome.css";
// Components
@import "./user_dashboard/app";
@import "./user_dashboard/user_dashboard";
@import "./user_dashboard/search";
@import "./user_dashboard/search-results";
$darkBlueColor: #092952;
$lightBlueColor: #edf3fb;
.alumni-select-date {
  .mx-input-wrapper{
    width: fit-content;
  }
  .mx-icon-calendar{
    display: none;
  }

}
.hide-logo{
  #doughnut-chart{
    background: unset !important;
  }
}
.top-bar{
  box-shadow: unset !important;
  background: white !important;
  .v-toolbar__title{
    font-size: 12px !important;
  }
}
.v-navigation-drawer--mini-variant{
  width: 63px !important;
}
.icon-blue-dark .v-btn__content{
  color: $darkBlueColor;

}
.v-date-picker-table{
height: 100% !important;
}
.v-main{
  margin-top: 50px;
}
.main-items .button-inline a {
  border-radius: 4px;
}
.v-input input,.v-textarea textarea{
  opacity: 1;
}
.secondary{
  padding-top: 50px;

}
.all-info{
  width: 100%;
}
.body,html{
  height: 100vh;
}
.body{
  background:#edf3fb;

}

.v-main{
  background:#edf3fb;
}
.search-container .container .row {
  background: rgba(237, 243, 251, 0.5);
}

.icon-display-page{
  .icon{
    margin-right: 10px;
  }
  .text{
    font-size: 1rem;
  }
}
.search-container .icon-display-page{
  margin-left: 37px;
  .text{
    font-size: 1rem;
  }

}
.v-select-list .v-list-item--active{
  background-color: #edf3fb !important;
}
.v-select-list .v-list-item{
  &:hover{
    background-color: #edf3fb !important;
  }
  .v-list-item__mask{
    background-color: #edf3fb !important;
  }
}
.v-select-list {
  .v-list-item:has(.selected-text) + .v-list-item:not(:has(.selected-text)){
  border-top: 3px solid grey;
  padding-top: 10px;
  }
}
.container-fluid {
  .search-container {
    .v-input--is-dirty {
        .v-select__slot{
          .v-input__append-inner {
            position: absolute !important;
            z-index: 5;
            right: 0px;
            top: -7px;
          }

          .v-input__append-inner:has(.v-input__icon--clear) {
            right: 20px;
          }

          .v-select__selections {
            max-height: 407px;
            overflow-y: auto;
            overflow-x: hidden;
            &::-webkit-scrollbar {
              width: 5px;
            }
            &::-webkit-scrollbar-thumb {
              background-color: #092952;
            }
          }


      }
    }

  }
}
.container-fluid  .search-container  .item-detailed , .container .search-container  .item-detailed {
  background: rgba(237, 243, 251, 0.5);
  margin-left: 100px !important;
  max-width: 700px !important;
  width: 100% !important;
}
.container-fluid .search-container{
  margin-right: 0;
}
 .container-fluid .search-container  button{
  border-radius: 10px;
}

.container-fluid .search-container  .v-input__slot{
  border:1px solid #092952;
  border-radius: 10px;

}

.search-container .v-input--is-disabled input, .search-container .v-input--is-disabled .v-input__slot{
  background-color: #edf3fb !important;
}
.v-input__icon.v-input__icon--append{
  opacity: 50%;
}
.container-fluid  .search-container{
  height: 100%;
  border-bottom: 20px solid #edf3fb;

}
.jobs-block button{
  min-width: 100px !important;
  margin-top: 5px;
  background-color: white !important;
}
.jobs-block {
  padding-right: 0 !important;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
}
.items-button{
  justify-content: space-between;
}
.items-button .button-inline button{
  min-width: 100px !important;
}
.search-container {
  width: 100%;
  height: 100%;
  background:white;
  background-image: url('/img/globe.png');
  background-repeat: no-repeat;
  background-size: 68%;
  background-position: bottom right;
  margin-bottom: 20px;
}
.item-edited.item-detailed{
  border: 1px solid lightslategrey;
  border-radius: 10px;
  padding:20px;
}
.container-fluid .search-container  .row input, .container-fluid .search-container  .row button,   .container-fluid .search-container .row .v-input__slot,   .container-fluid .search-container .row  .pqe-item{
  background: white;
}
//[class*="search"]  .container .icon-display-page{
//  background: rgba(255, 255, 255, 0.4);
//}
//[class*="search"]  .container .row:has(.search-criteria ){
//  background: rgba(255, 255, 255, 0.4);
//}
//[class*="search"]  .container .main-items{
//  background: rgba(255, 255, 255, 0.4);
//}
//[class*="search"]  .container .item-detailed{
//  background:white;.item-edited.item-detailed
//}
/*compare*/



.search-container .button-inline button{
  border-radius: 10px !important;
}
.search-container .ma-2.button-search, .search-container .pqe-items-all {
  border-radius: 10px !important;
}

.search-container .jobs-block button{
  border-radius: 10px !important;
  margin-right: 0;
}
.search-container .pqe-items-all{
  border:0.5px solid grey;
}

.search-container  input, .search-container  button, .search-container .v-input__slot{
  background: white !important;
}
//[class*="compare"]  .container .icon-display-page{
//  background: rgba(255, 255, 255, 0.4);
//}
//[class*="compare"]  .container .row:has(.search-criteria ){
//  background: rgba(255, 255, 255, 0.4);
//}
//[class*="compare"]  .container .main-items{
//  background: rgba(255, 255, 255, 0.4);
//}
//[class*="compare"]  .container .item-detailed{
//  background:white;
//}

.search-results .container{
  background: white;
}

.result-board{

 .icon-display-page{
    position: sticky;
    top:0;
    background: white;
    z-index: 10;
  }
  &.overflow-hidden{
    overflow: hidden !important;
  }
  background-color: white;
  .actions{
    overflow-x: auto;
    overflow-y:hidden;
    border:1px solid $darkBlueColor !important;
    .export-buttons{
      position: absolute;
      right: 0;
      bottom: -5px;
    }
  }
}
.result-board .row{
  background-color: white !important;
}
.items-table tbody tr:nth-child(1n){
  background-color: #d1dff0 !important;
}
@-moz-document url-prefix(){
  .search-board.secondary-block{
    input{
      &::after{
        top: -1px;
        left: -1px;
      }
    }
  }
}
.tabs-more{
  .nav-tabs{
    display: none;
  }
}
.tabs-info{
  position: sticky;
  background: white;
  z-index: 2;
  top: 51px;
  .tab-content{
    display: none;
  }
  .nav-link{
    white-space: pre-line;
    font-weight: bold;
    font-size: 16px;
    &.active{
      border-bottom: 3px solid $darkBlueColor;
    }
  }
}
.search-board.secondary-block{

  max-width: 256px !important;
  background: white;
  margin-right: 11px;
  margin-left: 13px;
  .checkbox-select__check-wrapp{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.4rem;
    label{
      margin-left: 3px;
      margin-bottom: unset !important;
      &:before{
        position: relative;
        top: -3px;
      }
    }

    input{
      -moz-appearance:initial;
      position: relative;
      top:-2px;
      width: 13px;
      height: 13px;
      &:after{
        content: "";
        border: 1px solid #092952;
        position: absolute;
        width:13px;
        height: 13px;

        background: white;
        cursor: pointer;
      }
    }
  }
  .v-slider{
    .v-slider__thumb-container{
        .v-slider__thumb-label{
          &:has(.merge-tooltip){
           width: 50px !important;
          }
        }
      &:last-child{
        .v-slider__thumb-label{
          &:has(.merge-tooltip){
            display: none;
          }
        }

      }
    }
  }

  .v-input__slider{
    padding-right: 8px !important;
    padding-left: 8px !important;
    .v-slider__track-fill.primary{
      background-color: $darkBlueColor !important;
    }

    .v-slider__thumb-label{
      background-color: #d1dff0 !important;;
      border-color: #d1dff0 !important;;
      border-radius: 10px;
      transform: unset !important;
      bottom: 5px;
      left: -15px;
      div{
        transform: unset;
        color:$darkBlueColor;
      }

    }
  }

  .v-slider__thumb{
    cursor: pointer;
    &:before{
      display: none !important;
    }
  }
  .v-text-field{
    .v-input__control{
      border: 1px solid $darkBlueColor;
    }
  }

}
.search-board.secondary-block::-webkit-scrollbar {
  width: 5px;
}
#card-info-popup::-webkit-scrollbar{
  width: 11px;
  height: 5px;
}
#card-info-popup::-webkit-scrollbar-thumb{
  background-color: #092952 ;
}
.search-results .actions::-webkit-scrollbar{
  width: 11px;
  height: 5px;
}

.v-dialog {
  &:not(.loading-dialog){
    &.v-dialog--active{
      img{
        margin-bottom: 20px;
      }
      min-width: 400px;
      width: fit-content;
    }
  }
}
body{
  &.modal-open{
    padding-right: 0 !important;
  }
}
.search-modal {
  border: unset !important;
  border-radius: 10px !important;
  min-height: 100px;
  .form-row{
    margin: 0 auto;
    margin-top: 20px;
    align-items: center;
    input, textarea{
      border-radius: 10px;
      border:1px solid $darkBlueColor !important;
    }
    button{
      text-transform: uppercase;
      border-radius: 10px;
      font-size: 12px;
      &.btn-danger, &.primary{
        color: white  !important;
        border: 1px solid $darkBlueColor !important;
        background: $darkBlueColor  !important;
      }
      &.btn-primary, &.error{
        background:lightgrey  !important;
        color: grey  !important;
        border: 1px solid $darkBlueColor !important;
      }
    }

  }
}
.banner-top{
  img{
    width: 20px;
  }
}
.result-board::-webkit-scrollbar{
  width: 11px;
  height: 5px;
}
.result-board::-webkit-scrollbar-thumb{
  background-color: #092952 ;
}
.result-board .table-container::-webkit-scrollbar{
  width: 11px;
  height: 5px;
}
.result-board .table-container::-webkit-scrollbar-thumb{
  background-color: #092952 ;
}
.search-board.secondary-block::-webkit-scrollbar-thumb {
  background-color: #092952 ;
}

/* Firefox */
.search-board.secondary-block{
  scrollbar-color:  #092952 white;
  scrollbar-width: thin;
}
.search-board.secondary-block::-moz-scrollbar-thumb {
  background-color: #092952;
  background: #092952;
}

/* Internet Explorer/Edge */
.search-board.secondary-block::-ms-scrollbar-thumb {
  background-color: #092952;
}
.search-results .actions::-webkit-scrollbar-thumb{
  background-color: #092952 ;
}
#search-results .secondary-block .search-title{
  background-color: white !important;
  color:black !important;
}
.search-board.secondary-block .v-card{
box-shadow: none !important;
}
.auto-compete input:checked{
  position: relative;
}
.auto-compete input:checked:before{
  content: "";
  background-color: #092952;
  width:13px;
  height: 13px;
  color: #092952;
  z-index: 99;
  position: absolute;
}
.search-results {
  .candidates {
    .v-expansion-panel-header{
      background: #edf3fb !important;
      .notes-icon{
         position: relative;
        left:18px;
      }
    }
  }
}
.recently-moved input[type="checkbox"]:checked + label:before{
  content: "" !important;
}
.bottom-container .candidate_container .candidates .candidate .details span.icon-span{
  width: 20px !important;
  margin-left: 0;
  margin-right: 0;

}
.bottom-container .candidate_container .candidates .candidate .details span.suggest-edit-icon{
  position:relative;
  left: 15px;
}
.icon-moved{
  float: right;
}
.items-table th.short{
  background: #092952;
  border-radius: 10px 0px 0px 0px;
}
.items-table th:last-child{
  border-radius: 0px 10px 0px 0px;
}
.all-graphs{
  .VueCarousel-wrapper{
    .VueCarousel-inner{
      flex-wrap: wrap;
      transform: unset !important;
    }
  }

}
.secondary-block{
  .checkbox-select__filters-wrapp{
  padding-left: 0;
  }
}

#profiles-export-configuration___BV_modal_outer_{
  z-index: 999999 !important;
}
#profiles-export-configuration{
  .modal-dialog{
    top:93px;
    align-items: baseline;
    max-width: 900px;
  }
  padding-right: 0 !important;
}

.search-results-page, .recently-moved{
  height:100%;
  padding-right: 0 !important;
}
.conditions-check {
 &+label{
    cursor: pointer;
  }
}
.save-as-default{
  position: absolute;
  right: 0;
  top: 10px;
  font-size: 11px;
  cursor: pointer;
  &.show-more-graphs {
    div{
      display: flex;
      justify-content: right;
      align-items: center;
      flex-wrap: wrap;
    }
    p{
      width: 100%;
    }

    text-align: center;
    position: relative;
    right: unset !important;
    top: unset !important;
    color: $darkBlueColor;
    text-transform: uppercase;
    cursor: pointer;
  }
}
.popup-candidate-info{
  font-size: 12px;

}
.promotion-noted{
  margin-left: 100px;
  max-width: 400px;
}
.popup-candidate-info{
  .info-popup-button{
    .info-action-button{
      border-radius: 10px;
      padding-left: 30px !important;
      padding-right: 30px !important;
      text-transform: uppercase;
    }
  }
  .tab-content{
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #092952;
    }
  }
}
.popup-candidate-info{
  font-size: 12px;
  .nav-tabs .nav-link.active{
    border-bottom: 3px solid #092952 ;
  }
  .nav-tabs .nav-link{
    text-transform: uppercase;
    font-weight: bold;
  }
}
.search-results, .result-board {
  .icon-display-page{

    justify-content: center;
    align-items: center;
    padding-top: 0px;
    margin-top: 7px !important;
    .show-more-graphs{
      margin-right: 10px;
    }
    .cls-1 {
      fill: #092952;
    }
    .icon{
      margin-right: 10px;
    }
  }
}



.search-board.secondary-block .auto-compete{
  margin-top: -7px !important;

}
.search-board.secondary-block .auto-compete .search-title{
  margin-top: 0 !important;
}
.search-board.secondary-block {
  .v-text-field {
    padding-left: 0 !important;
  }

}
.search-board.secondary-block .v-input--range-slider{
  margin-top: 40px;
  padding-left: 0 !important;
  .v-input__prepend-outer, .v-input__append-outer{
      .v-input.v-text-field{
        padding-top: 0 !important;
        margin-top: 0 !important;
        width: 40px !important;
        height: 30px !important;
        margin-bottom: 20px;
        margin-right: 0 !important;
        margin-left: 0 !important;
        padding-right: 0 !important;
        padding-left: 0 !important;
        .v-input__control{
          width: 100%;
          height: 100%;
          .v-input__slot{
            width: 100%;
            height: 100%;
            .v-text-field__slot{
              width: 100%;
              height: 100%;
              input{
                width: 100%;
                height: 100%;
                text-align: center;
              }
            }
          }
        }
      }
  }

}
.popup-candidate-info{
  border: 1px solid #092952;
}
.btn-change-pages{
  border: 1px solid #092952;
  fill: #092952;
  /* height: 28px; */
  /* text-align: center; */
  padding-top: 0.5px;
  padding-bottom: 0.5px;
  background-color:#edf3fb;
  font-size: 10px;
  padding-left: 15px;
  padding-right: 15px;
  &:hover, &:focus{
    background-color: #092952;
    fill:white;
  }
}

.btn-change-pages-right{
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-change-pages.btn-change-pages-left:not(.btn-change-candidates){
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.v-card__text.search-title{
  text-transform: capitalize;
}
.slider-exclude .v-card__text.search-title{
  text-transform: inherit;
}
button.clear-class.mt-auto{
  margin-top: 10px !important;
}
button.clear-class{
  color: #092952 !important;
  margin-top: 10px !important;
  margin-right: 11px !important;
  border:1px solid #092952;
  box-shadow: unset;
}
.select-columns{
  margin-top: auto !important;
}
.search-container .right-selects,  .search-container .left-selects{
  margin-top:0;
  margin-bottom:0;
  padding-top: 0;
  padding-bottom: 0;

}
.v-chip--select{
  background-color: #edf3fb !important;
}
.opacity-0{
  opacity: 0 !important;
  visibility: hidden;
}
.slider-exclude:not(.slider-exclude) .v-slider__ticks-container .v-slider__tick:not(:nth-child(6n)) {
  opacity: 0 !important;
}
.VueCarousel-navigation-prev{
  margin-left: 10px;
}
.VueCarousel-navigation-next{
  margin-right: 10px;
}
.v-navigation-drawer__content .img-icon{
  margin-right: 20px;
  max-width: 34px;
  min-width: 30px;
  width: 100%;
}
.popup-info-app{
  z-index: 9999999;
}
.v-navigation-drawer{
  top:20px !important;
}
.result-sort-buttons{
  align-items: center;
  padding-top:5px !important;
  button{
    padding: 0 8px !important;
  }
}
.show-less{
  max-height: 100px;

}
.chart-display .checked:after{
  background-color: #d1dff0;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  content: "";
  right: -20px;
  border-radius:10px;

}
.chart-display .checked{
  position: relative;
  z-index: 1;
}

.v-select.v-input--dense .v-chip{
  margin:4px !important;
  border-radius: 10px !important;
  height: 32px;
  position: relative;
  flex: 1 1 100%;
  .v-chip__content{
    overflow: hidden;
    padding-right: 20px;
  }
}
.v-input__append-inner{
  .v-input__icon--clear{
    display: none;
  }
}
.v-select.v-input--dense .v-chip .v-chip__close{
  opacity: 0.7;
  position: absolute;
  right: 7px;
  z-index: 6;
}

.v-application--is-ltr .v-chip .v-chip__close.v-icon.v-icon--right{
  width: 24px;
  height: 24px;
  color:black;
  opacity: 0.54;
  margin: 4px;
}
.v-text-field--outlined.v-input--dense .v-label.v-label--active  {
  z-index: 1;
  background: #edf3fb;
}
.v-tooltip__content{
  background: white !important;
  color: black !important;
}

.searches-page{
  .theme--light.v-btn.v-btn--disabled.v-btn--has-bg{
    background-color: #09295273 !important;
    color:white !important;
    opacity: 0.7 !important;
  }
  height: 100%;
    .v-btn--disabled {
      .spinner-border {
        width: 1rem;
        height: 1rem;
        margin-right: 5px;
      }
    }
}

.select-date-block button.date-picker-button {

  width: 23%;
  border: none;
  color: grey !important;
  text-transform: capitalize;
  border-bottom: 1px solid $darkBlueColor;
  padding: 0;
  background: transparent !important;
  padding-bottom: 0px !important;
  border-radius: 0 !important;
  text-align: left !important;
  padding-left: 5px !important;
  font-size: 12px;
  .v-btn__content{
    display: block;
    text-align: left;
  }
}
.container-fluid.searches-page{
  padding-left: 20px;
}
.menu-main{
  background: white;
  margin-top: -6px;
  padding-left: 20px;
  padding-top: 20px;
}
.row.one-line-select{
  padding-top: 0;
  margin-top: 0 !important;
}
.search-menu-nav .nav-tabs{
  border-bottom: unset;

}
.search-menu-nav .nav-tabs .nav-item{
  border-bottom: unset;
}

/*date picker*/
.v-application .v-picker__title.primary{
  background-color: white !important;
  .v-date-picker-title{
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    div{
      background: #edf3fb;
      color: grey;
      margin: 0;
      padding: 5px;
      border-radius: 10px;
      font-size: 12px;
      &.v-date-picker-title__date{
        display: none !important;
      }
    }
  }
}
.saved-search{
  .v-picker {
    .v-picker__title.primary{
      background-color: white !important;
      .v-date-picker-title{
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: row;
        div{
          background: #edf3fb;
          color: grey;
          margin: 0;
          padding: 5px;
          border-radius: 10px;
          font-size: 12px;
          &.v-date-picker-title__date{
            display: block !important;
          }
        }
      }
    }
  }
}

.boolean-combo{
  .v-autocomplete{
    .v-input__control{
      .v-select__slot{

        .v-label{
          font-size: 10px !important;

        }
        .v-select__selections{
          input::placeholder {
            color: transparent;
          }
        }
      }
    }
  }
}
.v-picker.v-card.v-picker--date{
  color: grey !important;
  border-radius: 10px !important;
  border: 2px solid #092952;
  left: unset !important;
  right: 20px !important;
  top: 0 !important;

    .v-date-picker-table{
      button{
        .v-btn__content{
          color:grey;
        }

      }
      button.v-btn--active{
        background-color: #edf3fb !important;
      }
      button.v-btn--active.v-date-picker--first-in-range{
        background-color: #092952 !important;
        &:before{
          opacity: 0 !important;
        }
        .v-btn__content{
          color:white !important;
        }
      }
      button.v-btn--active.v-date-picker--last-in-range {
        &:before{
          opacity: 0 !important;
        }
        background-color: #092952 !important;
        .v-btn__content{
          color:white !important;
        }

      }
    }
    .v-date-picker-header {
      .v-date-picker-header__value {
        .accent--text{
          button{
            color: grey !important;
          }
        }

      }
      .v-btn{
        background: #edf3fb !important;
        border-radius: 10px !important;
        padding: 5px;
      }

    }
}
.app-left-nav{
  .v-list-item{
    margin-bottom: 20px !important;
    .v-list-item__content {
      .v-list-item__title{
        font-size: 12px !important;
      }

    }
  }
}
.input[type="radio"], input[type="checkbox"],  .slider.round, .candidate_container .candidates .candidate .v-expansion-panel-header .name i{
  border:1px solid #092952;
}
a.not-exist-icon-link {
  pointer-events: none;

}
.search-results-page{
  .result-board {
    .VueCarousel-navigation {
      .VueCarousel-navigation-button {
        top: 10vw;

        img {
          width: 1.5vw;
        }
      }


    }
  }
}
.recently-moved{
  .result-board {
    .VueCarousel-navigation {
      .VueCarousel-navigation-button {
        top: 10vw;

        img {
          width:25px
        }
      }


    }
  }
}

.v-autocomplete__content{
  z-index: 999999 !important;
}
.search-line{
  width: 100%;
  margin: 0 auto;
  margin-top: 10px;
  background: #092952;
}
.compare-page{

  .result-board {

    .graph-length2{
      .VueCarousel-slide {
        padding-top: 0;
        padding-bottom: 0;
        &:nth-child(1){
          .chart-display{
            &:nth-child(2){
              &:before{
                content: "";
                width: 100%;
                position: absolute;
                height: 0px;
                border: unset !important;
                right: 0;
              }
            }
          }

        }

      }
    }
    .all-graphs {
      &.graph-length3,&.graph-length4{
        .VueCarousel-slide {
          padding-top: 0;
          padding-bottom: 20px;
          &:nth-child(1){
            &:after {
              content: "";
              width: 100%;
              position: absolute;
              height: 0px;
              border: unset !important;
              margin-top: -13px;
              right: 0;
            }
          }

        }
      }
    }
    .VueCarousel-navigation {
      .VueCarousel-navigation-button {
        img {
          width: 20px;
        }
      }


    }
  }
}
.law-society-select {
    flex-wrap: wrap !important;
  display: flex;
}
  .table-container
  {
    .items-table{
      thead{
        tr{
          .table-head{
            .dropdown-table{
              overflow:hidden;
              ul{
                overflow-x: hidden;
                li{
                  div{
                    width: 100%;
                    text-align: left;
                    label{
                      max-width: 120px;
                      word-break: break-word;
                    }
                  }

                }
              }
            }
          }
        }
      }
    }
  }

.action-saved-search  {
  .v-input__slot{
    &:before{
      display: none;
    }
    &:after{
      display: none ;
    }
  }
  .search-saved-input{
    .v-input__prepend-inner{
      i{
        color:  $darkBlueColor;

      }
      margin-top: 0 !important;
    }
  }
  .saved-searches-select{
    max-width: 200px;
    .v-input__prepend-inner{
      margin-top: 0 !important;
      width: 100px;
      color:  $darkBlueColor;
      align-self: center;
      padding-left: 10px;
      font-weight: bold;
    }
  }
  .v-input{
    color: $darkBlueColor !important;
    .v-input__control{
      .v-input__slot{
        border: 2px solid  $darkBlueColor !important;
        border-radius: 10px;
        height: 28px;
      }
    }
    &.v-input--is-dirty{
      color: $darkBlueColor!important;
      caret-color: $darkBlueColor !important;
      }
  }

}
.choose-search {
  .v-expansion-panel-header{
    //background: #edf3fb !important;
  }
  .one-candidate-block{
    .v-expansion-panel{
      border: solid 1px #092952 !important;
      border-radius: 11px !important;
      .v-expansion-panel-header{
        background: #edf3fb !important;
      }
    }


    span{
      &.icon-span{
        width: unset !important;
      }
    }
  }

}
.dashboard-candidate{
  .name{
    max-width:40%;
    flex-wrap: wrap;
    justify-content: flex-start !important;

  }
  .theme--light.v-expansion-panels{
    .v-expansion-panel{
      background-color: #d1dff0;
    }
  }
  .mobile{
    margin-right: 0 !important;
  }
  .linkedin{
    margin-left: 0 !important;
    margin-right: 10px !important;
  }
  .website{
    margin-right: 5px;
  }
  .firm-title-top{
    max-width: 100px !important;
  }
}
.cls-linkedin-disabled-1{fill:#fff;}.cls-linkedin-disabled-2{fill:#888688;}
.cls-linkedin-icon-1 {
  fill: #fff;
}

.cls-linkedin-icon-2 {
  fill: #087ebc;
}
.cls-website-disabled-1{fill:#888688;}
.cls-website-disabled-2{fill:#fff;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:6.39px;}
.cls-website-icon-1 {
  fill: #fff;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 6.39px;
}

.cls-website-icon-2 {
  fill: #1b2d4e;
}
.cls-mobile-disabled-1{stroke-width:5.65px;}
.cls-mobile-disabled-1,.cls-mobile-disabled-2{fill:#888688;}
.cls-mobile-disabled-1,.cls-mobile-disabled-3{stroke:#fff;stroke-linecap:round;stroke-linejoin:round;}.cls-mobile-disabled-3{fill:#fff;stroke-width:4.82px;}
.cls-mobile-1, .cls-mobile-2 {
  fill: #2bb29d;
}

.cls-mobile-2 {
  stroke-width: 5.65px;
}

.cls-mobile-2, .cls-mobile-3 {
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.info-action-button{
  &.lightbluebutton{
    &:not(.selected-button-dark){
      border: 1px solid $darkBlueColor ;
      background-color: $lightBlueColor !important;
      color:$darkBlueColor !important;
    }

  }
}

.cls-mobile-3 {
  fill: #fff;
  stroke-width: 4.82px;
}
.info-block-numbers{
  padding-left: 10px;
  padding-right: 4px;
}
#my-app:has(.all-info){
  overflow: hidden;
  height: 96vh;

}
#my-app:has(.overflow-hidden){
  .v-alert-delete{
    top: 200px !important;
  }
  margin-top:0;
  overflow: hidden;
  .result-board{
    height: 0;
  }
  .saved-search-result{
    height: 0;
  }
  .dashboard-page{
    display: none;
    height: 0;
  }
  .search-board{
    height: 0;
  }
}
.info-popup-content{

  border-right: 10px solid #edf3fb;
  border-top: 10px solid #edf3fb;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  top:0;
  &.rework-info-content{
    z-index: 12;
  }
  .tab-content{
    font-size: 0.875rem;
    white-space: pre-wrap;

    white-space: -moz-pre-wrap;
    padding-left: 25px;
    padding-right: 25px;
    .tab-info{
      padding-bottom: 0;
      .item-row{
        padding-bottom: 0;
      }
    }
  }
  button.profile-export-button{
    border-radius: 4px;
    background-color:#edf3fb !important;
    color: #092952 !important;
    border: 1px solid #092952 !important;
  }
  &::after{
    content: '';
    width: 30px;
    height: 100%;
    background: #edf3fb;
    display: block;
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
  }
  &::before{
    content: "";
    width: 10px;
    height: 100%;
    background: #edf3fb;
    display: block;
    z-index: 9;
    position: absolute;
    top: 0;
    left: 16%;
  }
  margin-top: 25px;

  background-color: white;
  position: absolute;
  z-index: 5;
  width: 100%;
  padding-right: 0;
  border-left: 15px solid #edf3fb;
  min-width: 1000px;
  max-height: 100%;
  height: -webkit-fill-available;
  .tabs{
    .nav-tabs{
      //justify-content: space-between;
    }
  }
}
button{
  &.selected-button-dark{
    background-color: $darkBlueColor !important;
  }
}
.btn-change-candidates{
  border-radius: 5px;
  &:hover{
    color: white;
  }
  &:focus{
    color: white;
  }
  &:visited{
      color: white;
  }
}
.dashboard{
  .theme--light.v-tabs>.v-tabs-bar{
    width: 100%;
  }
}
.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.boolean-combo{
  .v-chip{
    min-height: 43px;
    height: 100% !important;
    .v-chip__content{
      text-wrap: wrap;
    }
  }

}
.contact-form{
  .text-area-input{
    label{
      font-size: 1rem !important;
      &.v-label--active{
        transform: translateY(-16px) scale(1) !important;
      }
    }
  }
  .radio-custom {
    opacity: 0 !important;
    position: absolute;
  }

   .radio-custom, .radio-custom-label {
    display: inline-block;
    vertical-align: middle;
    margin: 5px;
    cursor: pointer;
  }

  .radio-custom-label {
    display: inline-flex;
    position: relative;
  }

   .radio-custom + .radio-custom-label:before {
    content: '';
    background: #fff;
    border: 2px solid $darkBlueColor;
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    text-align: center;
  }


  .radio-custom + .radio-custom-label:before {
    border-radius: 50%;
  }

  .radio-custom:checked + .radio-custom-label:before {
    content: "\f00c";
    font-family: 'FontAwesome';
    color: white;
    background-color: $darkBlueColor; ;
  }

   .radio-custom:focus + .radio-custom-label {
    outline: 1px solid #ddd; /* focus style */
  }
}
.suggest-edit-popup{
  textarea{
    border-bottom: 1px solid grey;
    resize: none;
    width: 100%;
    outline: none !important;
  }
  .__edit-header{
    text-transform: uppercase;
    color: grey;
  }
  .__edit-send-button{
    cursor: pointer;
    text-align: right;
    color: grey;
    text-transform: uppercase;
  }
}
.notes-block{
  font-size: 0.875rem;
  background-color: $darkBlueColor;
  color: white;
  border-radius: 20px;
  position: fixed;
  width: 18%;
  z-index: 10;
  .notes_body{
    margin: auto !important;
    max-height: 400px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #092952;
    }
  }
  #search-notes{
    color:white;
    outline: none;
  }
  .one-note{
    border-bottom: 1px solid white;
    .__img-note-edit{
      cursor: pointer;
      width: 20px;
      position: absolute;
      right: 0;
    }
    textarea{
      color: white;
      outline: none !important;
    }
  }
  .note-input{
   position: relative;
    textarea{
      overflow: hidden;
      border-bottom: 1px solid white;
      width: 100%;
      color: white;
      resize:none;
      outline: none !important;
      &::placeholder{
        color: white;
      }
    }

  }
  .notes{
    .__search{
      label{
        margin-bottom: 0;
      }
    }
  }
  .note-save{
    cursor: pointer;
    text-transform: uppercase;
    text-align: right;
  }
}
.cls-email-1 {
  fill: #112d50;
  stroke-width: 0px;
}

.cls-email-2 {
  fill: #fff;
  stroke: #112d50;
  stroke-miterlimit: 10;
  stroke-width: 3px;
}
.cls-disabled-email-1 {
  fill: #808285;
  stroke-width: 0px;
}

.cls-disabled-email-2 {
  fill: #fff;
  stroke: #808285;
  stroke-miterlimit: 10;
  stroke-width: 3px;
}
.share-autocomplete{
  .v-input__slot{
    border-radius: 10px !important;
    border: 1px solid #092952 !important;
  }
}

html {
  font-size: 12px;
  overflow-y: auto !important;
}


.v-expansion-panel::before {
  box-shadow: none !important;
}

.candidate_container {
  .candidates {
    display: inherit;
    height: 250px;
    overflow-y: scroll;
    border-bottom: solid 1px #c8c8c8;

    .candidate {
      margin: 2px 0;
      font-size: 0.75rem;

      .v-expansion-panel-header {
        font-size: 0.75rem;
        padding: 1em 1em !important;
        min-height: 20px !important;
        border-bottom: solid 1px #dfdfdf;
        background-color: #E0E0E080;

        .v-expansion-panel-header__icon {
          display: none !important;
        }

        .name {
          //position: relative;
          //max-width: 10rem;

          .fa-check {
            color: #173257;
          }

          .fa-times {
            color: #dc3545;
          }

          i {
            position: absolute;
            left: 0;
            bottom: 0;
          }
        }

        .details {
          position: relative;
          text-align: right;

          span {
            font-weight: 600;


            &.firmranking {
              margin-right: 10px;
            }

            &.linkedin {
              margin-left: 1rem;
              margin-right: 5px;
            }

            &.icon-span {
              display: inline-block;
              width: 0.6rem;;
            }

            a.icon-position {
              display: inline-block;
            }

          }


          i.vertical-divider {
            display: inline-block;
            width: 1px;
            height: 2.6em;
            position: absolute;
            font-style: normal;
            margin-left: 2px;
            margin-right: 5px;
            border-left: solid 1px rgba(0, 0, 0, 0.25);
            top: -0.5em;
          }
        }
      }

      .v-expansion-panel-content {
        position: relative;
        background: #fff;
        border-radius: 0 0 4px 4px;
        border-left: 1px solid rgba(0, 0, 0, 0.125);
        border-right: 1px solid rgba(0, 0, 0, 0.125);
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);

        .suggest-an-edit {
          position: absolute;
          top: 12px;
          right: 20px;
        }

        .col-6 {
          flex-wrap: wrap;

          .item-row {
            padding-top: 0;
            padding-bottom: 1em;

            .key {
              width: 40%;
              display: inline-block;
              font-weight: bold;
            }

            .value {
              display: inline-block;

              a {
                color: #116cec;
              }
            }
          }

        }
      }
    }
  }
}


.validation-error {
  border: solid 1px red;
}

.v-alert {
  position: fixed !important;
  top: 4rem;
  right: 1rem;
}

.active-history {
  color: #030f6c;
  font-weight: bold;
}

.bottom-border {
  border-bottom: 1px solid #d2d2d2;
}


.logo-img {
  width: 10em;
}

.auto-compete {
  padding: 0 !important;

  .v-input__slot {
    padding-right: 0 !important;

    .v-chip--select {
      padding: 0 4px !important;
      margin: 4px 2px 0 0;
      color: black;
    }

    .v-input__append-inner {
      margin-left: 0;
      padding-left: 0;

      .v-input__icon {
        width: 16px;
        height: 16px;
        min-width: 16px;
        margin-right: 4px;

        .v-icon {
          font-size: 16px;
        }
      }
    }

  }
}

.color-span {
  display: inline-block;
  border-radius: 1em;
  height: 1em;
  min-width: 2em;

  &.text-span {
    text-align: center;
    height: initial;
    min-width: 4em;
    margin-right: 4px;
    color: #222;
    display: none;
  }
}

.font-size-small {
  font-size: small !important;
  line-height: 2em;
}

.v-list-item__subtitle, .font-12, .cand-item {
  font-size: 12px!important;
}

.mt-1 .label, .font-11, .v-toolbar__title, .v-card__text.search-title,
.checkbox-select__check-wrapp label{
  font-size: 11px!important;
}
